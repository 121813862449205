import VulnerabilityListComponent from "../../components/vulnerability-list-component/VulnerabilityList";
import PageTitle from "../../components/page-title/PageTitle";
import PageSectionComponent from "../../components/page-section-component/PageSectionComponent";

const VulnerabilityDatabase = () => {
    return (
        <>
            <PageTitle title="Vulnerability Database" />
            <div className="py-20">
                <PageSectionComponent
                    title="Vulnerability Database"
                    subtitle="Vulnerability Database"
                    longtext={[
                        "The IDCD Vulnerability Database is a comprehensive collection of vulnerabilities that have been identified and reported by security professionals to public CVE databases."
                    ]}
                    component={
                        <div className="pt-10">
                            <VulnerabilityListComponent />
                        </div>
                    }
                />
            </div>
        </>
    );
}

export default VulnerabilityDatabase;