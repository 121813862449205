import React, { useState } from 'react';
import ArrowIcon from '../../svg/arrow-icon/ArrowIcon';

const SubmenuComponent = ({ title, links, isOpen, href }) => {
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const hasSublinks = links && links.length > 0;

    // If it's a direct link (no sublinks), render a simple anchor
    if (!hasSublinks && href) {
        return (
            <a 
                href={href}
                className=" text-menu-text hover:text-menu-text-hover py-5 transition-colors duration-300"
            >
                {title}
            </a>
        );
    }

    // Otherwise, render the dropdown menu
    return (
        <div 
            className="relative"
            onMouseEnter={() => !isOpen && setDropdownOpen(true)}
            onMouseLeave={() => !isOpen && setDropdownOpen(false)}
        >
            <div 
                className={`group justify-between py-5 text-menu-text hover:text-menu-text-hover flex items-center cursor-pointer select-none`} 
                onClick={() => setDropdownOpen(!isDropdownOpen)}
            >
                {title}
                <div className={`ml-2 transform transition-transform duration-500 ${isDropdownOpen ? 'rotate-180' : ''}`}>
                    <ArrowIcon />
                </div>
            </div>
            {isDropdownOpen && (
                <div className={`submenu-dropdown ${isOpen ? 'mobile' : 'desktop'}`}>
                    <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                        {links.map((link, index) => (
                            <a 
                                key={index} 
                                href={link.href} 
                                className="block px-4 py-2 hover:bg-white hover:bg-opacity-10" 
                                role="menuitem"
                            >
                                {link.text}
                            </a>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default SubmenuComponent;