import React from 'react';
import AccordionComponent from '../accordion-component/AccordionComponent';

const PageSectionComponent = (props) => {





    return (
        <div className={`bg-primary py-20`} id={props.anchor}>
            
            <div className="w-4/5 md:w-2/3 mx-auto items-center">

                <div className="space-y-4 pb-2.5">
                    <div className={`flex items-center`}>
                        <hr className="border-t border-white w-16 mr-2" />
                        <h2 className="text-base font-bold text-left text-white font-calsans">{props.subtitle}</h2>
                    </div>
                    <h2 
                        className="font-bold text-4xl leading-[1.1] sm:text-3xl md:text-5xl"
                    >
                        <span className="font-calsans bg-gradient-to-r from-[#9867F0] to-[#ED4E50] bg-clip-text text-transparent">
                            {props.title}
                        </span>
                    </h2>
                </div>

                <p className="mt-5 leading-7 text-left lg:text-justify text-xs-base text-menu-text">
                    {props.longtext.map((item, index) => (
                        <React.Fragment key={index}>
                            {item.includes("id='custom-section'") ? (
                                <span dangerouslySetInnerHTML={{ __html: item }} />
                            ) : (
                                <>
                                    {item}
                                    <br />
                                    {index < props.longtext.length - 1 && <br />}
                                </>
                            )}
                        </React.Fragment>
                    ))}
                </p>

                {props.listItems &&
                    <ol className="list-decimal list-inside pl-5 space-y-4 pt-5 text-xs-base text-menu-text">
                        {props.listItems.map((item, index) => (
                            <li key={index}>
                                <strong>{item.title}:</strong> {item.description}
                            </li>
                        ))}
                    </ol>
                }

                {props.component &&
                    <div className="mt-5">
                        {props.component}
                    </div>
                }

            </div>
        </div>
    );
};

export default PageSectionComponent;