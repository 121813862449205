/**
 * 
 * 
 * Contact Form Component
 * 
 * 
 */

import React, { useState } from 'react';
import ButtonComponent from '../button-component/ButtonComponent';

const ContactFormComponent = () => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState(''); 

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Form submitted');
    }

    return (
        <div className="items-center">
            <form className="mt-8" onSubmit={handleSubmit}>
                <div className="grid grid-cols-1 gap-6">
                    <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
                        <label className="block text-sm">
                            <span className="text-gray-300">Full Name</span>
                            <input 
                                type="text" 
                                className="italic text-white form-input bg-transparent mt-1 block w-full p-4 border-solid border-b border-y-white focus:outline-none " 
                                placeholder="Enter your name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </label>
                        <label className="block text-sm">
                            <span className="text-gray-300">Email Address</span>
                            <input 
                                type="email" 
                                className="italic text-white form-input bg-transparent mt-1 block w-full p-4 border-solid border-b border-y-white focus:outline-none " 
                                placeholder="Enter your email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </label>
                    </div>
                    <label className="block text-sm">
                        <span className="text-gray-300">Message</span>
                        <textarea 
                            className="italic text-white form-textarea bg-transparent mt-1 block w-full p-4 border-solid border-b border-y-white focus:outline-none " 
                            rows="3" 
                            placeholder="Enter your message"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                        ></textarea>
                    </label>
                </div>
                <div className="mt-6">
                    <ButtonComponent text="Submit" type="submit" className='w-full'>
                        Submit
                    </ButtonComponent>
                </div>
            </form>
        </div> 
    );
}

export default ContactFormComponent;