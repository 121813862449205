import React, { useRef, useEffect } from 'react';
import './BannerComponent.css';
import ButtonComponent from '../button-component/ButtonComponent';

const BannerComponent = ({ title = "IDCD" }) => {
    const bannerRef = useRef(null);

    useEffect(() => {
        const handleMouseMove = (event) => {
            const banner = bannerRef.current;
            const rect = banner.getBoundingClientRect();
            const x = event.clientX - rect.left;
            const y = event.clientY - rect.top;
            banner.style.setProperty('--mouse-x', `${x}px`);
            banner.style.setProperty('--mouse-y', `${y}px`);
        };

        const banner = bannerRef.current;
        banner.addEventListener('mousemove', handleMouseMove);

        return () => {
            banner.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    // outer div has bg-primary with circle gradient
    return (
        <div className="bg-primary py-36 h-auto relative overflow-hidden">
            {/* Radial gradient overlay */}
            <div 
                className="absolute inset-0 pointer-events-none"
                style={{
                    background: 'radial-gradient(circle at center, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0) 40%)'
                }}
            />
            <div className="h-full flex items-center justify-center w-full">
                <div className="w-full px-4 mx-auto items-center relative">
                    <h1 
                        ref={bannerRef} 
                        className="banner-text text-center select-none w-full 
                                   text-9xl lg:text-[16rem] 
                                   leading-none font-calsans"
                    >
                        {title}
                    </h1>
                    {/** 
                    <div className="text-center text-lg lg:text-3xl text-text font-light opacity-80 select-none">
                        Initiative for Diabetes Cyber Defence
                    </div>
                    */}
                    <div className="w-[5.5em] h-[1px] bg-text opacity-50 my-4 text-center mx-auto"></div>
                    {/* Announcing the IDCD API */}
                    <div className="text-center text-lg lg:text-3xl text-text font-light opacity-80 select-none">
                        <h3 className='font-bold text-2xl leading-[1.1] sm:text-3xl md:text-4xl font-calsans'>
                            Announcing the IDCD API
                        </h3>
                        {/** Buttons to learn more and get involved */}
                        <div className="flex justify-center gap-4 mt-5"> 
                            <ButtonComponent 
                                variant="primary"
                                // redirect to vulnerability database page in the same tab
                                onClick={() => window.location.href = '/vulnerability-database'}
                            >
                                Learn More
                            </ButtonComponent>
                            <ButtonComponent 
                                variant="secondary"
                                // redirect to get involved page in the same tab
                                onClick={() => window.location.href = '/get-involved'}
                            >
                                Get Involved
                            </ButtonComponent>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BannerComponent;